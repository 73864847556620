// https://reactjs.org/docs/optimizing-performance.html#using-immutable-data-structures

// types
const types = {
  SYS_TOKEN: 'SYS/TOKEN',
  SYS_RULE_ORG: 'SYS/RULE-ORG',
};

// state
const initialState = {
  token: 'token',
  ruleOrg: {},
};

// action creators
const actions = {
  setToken: (d) => ({
    type: types.SYS_TOKEN,
    payload: d,
  }),
  setRuleOrg: (d) => ({
    type: types.SYS_RULE_ORG,
    payload: d,
  }),
};

// reducers
const reducers = (state = initialState, action) => {
  switch (action.type) {
    case types.SYS_TOKEN:
      return { ...state, token: action.payload };
    case types.SYS_RULE_ORG:
      console.log(action.payload);
      return { ...state, ruleOrg: action.payload };
    default:
      return state;
  }
};

// export
export {
  initialState, types, actions, reducers,
};

// aes
const CONST_AES = Object.freeze({
  KEY: '$1qaz2wsx3edc4r$',
  IV: '$1qaz2wsx3edc4r$',
});

// http
const { baseUrl } = window['runtime-config'];

const CONST_HTTP = Object.freeze({
  HTTP_ROOT: baseUrl,
  TOKEN_RENEW_URL: '/admin/v1/user/token-renew',
});

const COLORS = {
  RED: '#FF8888',
  ORANGE: '#FFBB66',
  YELLOW: '#FFFF77',
  GREEN: '#66FF66',
  GRAY: '#C0C0C0',
};

export { CONST_AES, CONST_HTTP, COLORS };
